import React, { useState, useEffect } from "react";
import Header from "../Header/index";
import Auth from "../../../../utils/auth";
import { useNavigate, useParams } from "react-router-dom";
import {
  addFile,
  updateAgent,
  getAgentProfile,
  getUserProfile,
} from "../../../../utils/api";
import PlacesAutocomplete from "../../../../components/PlacesAutocomplete";
import imagePath from "../../../../utils/image";
import {
  removeFromArr,
  arrayContains,
  replaceSpecialChar,
  formatPhone,
  getCurrentURl,
} from "../../../../utils/helpers";

const Dashboard = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [agent, setAgent] = useState("");
  const [user, setUser] = useState("");
  // Agent information/Form State
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [languages, setLanguages] = useState("");
  const [license, setLicense] = useState("");
  const [brokerage, setBrokerage] = useState("");
  const [brokerageNumber, setBrokerageNumber] = useState("");
  const [description, setDescription] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [areas, setAreas] = useState([]);
  const [primaryLanguage, setPrimaryLanguage] = useState("");

  const [formattedAddress, setFormattedAddress] = useState("");

  // Submit/validation state
  const [showError, setShowError] = useState(false);
  const [emailAlert, setEmailAlert] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);

  const { id } = useParams();

  const getAreas = (data) => {
    if (data.areas_served[0]) {
      return data.areas_served.split("; ");
    } else {
      return [];
    }
  };

  // Run Query Once
  useEffect(() => {
    // User token
    let currentUser;

    // User token
    if (Auth.loggedIn() !== false) {
      currentUser = Auth.getUser();
      getUserProfile(currentUser.id)
        .then((res) => res.json())
        .then((data) => {
          console.log(data);

          // Set the from states with db data
          setUser({
            id: data.id,
            name: data.name,
            email: data.email,
          });

          getAgentProfile(id)
            .then((res) => res.json())
            .then((data) => {
              console.log(data);
              // Set the from states with db data
              setAgent(data);

              setFirstName(data.first_name);
              setLastName(data.last_name);
              setEmail(data.email);
              setPhoneNumber(data.phone_number);
              setLanguages(data.languages_spoken);
              setLicense(data.state_license_numbers);
              setBrokerage(data.brokerage);
              setBrokerageNumber(data.brokerage_number);
              setDescription(data.profile_description);
              setPrimaryLanguage(data.primary_language);
              setAreas(getAreas(data));

              setLoading(false);
            })
            .catch((err) => console.log(err));
        });
    } else {
      navigate(`/user-login`);
    }
  }, []);

  const displayPhoto = (image) => {
    if (!image) {
      return "https://i.postimg.cc/pXD3gSWd/pngfind-com-placeholder-png-6104451.png";
    } else if (image.includes("http") || image.includes("https")) {
      return `${image}`;
    } else {
      return `${imagePath}/${image}`;
    }
  };

  // Check if User can submit
  useEffect(() => {
    if (description.length > 1500) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [description]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Upload the profile image if applicable
    const iframe = document.querySelector("#image-upload");
    iframe.contentWindow.postMessage(`Submitting`, "*");
  };

  const saveData = (fileName) => {
    let profileFile = "";
    profileFile = fileName;

    const formData = {};

    // Check which fields need to be updated
    if (
      firstName !== "" &&
      agent.first_name !== firstName &&
      lastName !== "" &&
      agent.last_name !== lastName
    ) {
      formData.first_name = firstName;
      formData.last_name = lastName;
      formData.full_name = `${firstName} ${lastName}`;
    } else if (firstName !== "" && agent.first_name !== firstName) {
      formData.first_name = firstName;
      formData.full_name = `${firstName} ${agent.last_name}`;
    } else if (lastName !== "" && agent.last_name !== lastName) {
      formData.last_name = lastName;
      formData.full_name = `${agent.first_name} ${lastName}`;
    }

    if (email !== "" && agent.email !== email) {
      formData.email = email;
    }
    if (phoneNumber !== "" && agent.phone_number !== phoneNumber) {
      formData.phone_number = phoneNumber;
    }
    if (languages !== "" && agent.languages_spoken !== languages) {
      formData.languages_spoken = languages;
    }
    if (areas.join("; ") !== "" && agent.areas_served !== areas.join("; ")) {
      formData.areas_served = areas.join("; ");
    }
    if (license !== "" && agent.state_license_numbers !== license) {
      formData.state_license_numbers = license;
    }
    if (brokerage !== "" && agent.brokerage !== brokerage) {
      formData.brokerage = brokerage;
    }
    if (brokerageNumber !== "" && agent.brokerage_number !== brokerageNumber) {
      formData.brokerage_number = brokerageNumber;
    }
    if (description !== "" && agent.profile_description !== description) {
      formData.profile_description = description;
    }
    if (primaryLanguage !== "" && agent.primary_language !== primaryLanguage) {
      formData.primary_language = primaryLanguage;
    }

    console.log(profileFile);
    // Check if an image was uploaded
    if (profileFile !== "") {
      formData.image = profileFile;

      // Create the Agent
      if (Object.keys(formData).length === 0) {
        return;
      } else {
        makeAgentUpdates(formData);
      }
    } else {
      if (Object.keys(formData).length === 0) {
        return;
      } else {
        makeAgentUpdates(formData);
      }
    }

    async function makeAgentUpdates(formData) {
      const response = await updateAgent(formData, agent.id);

      if (!response.ok) {
        const errorMessage = await response.json();
        const message = errorMessage.errors[0].message;

        // Handle Unique Email Validation Error
        if (message === "email must be unique") {
          console.log("Email Should be Unique");
          setEmailAlert(true);
        } else {
          console.log(message);
          setShowError(true);
        }
      } else {
        window.location.reload();
      }
    }
  };

  // ---------------- MESSAGE CENTER -----------------
  window.onmessage = function (event) {
    console.log(event.data);
    // Listens for Parent Form Submission
    if (typeof event.data === "string" && event.data.includes("fileName")) {
      // Lets the Parent Window know if an image has been uploaded
      const fileName = event.data.split(":")[1];
      setProfileImage(fileName);
      saveData(fileName);
    } else if (
      typeof event.data === "string" &&
      event.data.includes("No Image For Upload")
    ) {
      setLoading(false);
      saveData();
    }
  };

  if (isLoading) {
    return <>loading...</>;
  }

  return (
    <div
      className={
        agent.primary_language === "English"
          ? "agent-portal"
          : "agent-portal busca"
      }
    >
      <Header user={user} />

      {/* Main */}
      <main>
        <div className="ap-edit-profile">
          <h1>Edit Profile Information</h1>

          <main className="agent-profile-page edit-profile">
            <div className="agent-col-1">
              <div className="loans-input-flex agent-name-input">
                <div>
                  <label htmlFor="firstname" className="loans-form-label">
                    First name
                  </label>
                  <input
                    type="text"
                    name="firstname"
                    id="firstname"
                    className="loans-form-input"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="lastname" className="loans-form-label">
                    Last name
                  </label>
                  <input
                    type="text"
                    name="lastname"
                    id="lastname"
                    className="loans-form-input"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              <img
                src={displayPhoto(agent.image)}
                alt="Agent Headshot"
                className={
                  !agent.image ? "agent-photo" : "agent-photo with-border"
                }
              />

              {/* Iframe to Upload Image */}
              <iframe
                id="image-upload"
                src={`${getCurrentURl()}fahiu2024`}
                title="Listing search"
                className="agent-signup-form"
                style={{
                  width: "100%",
                  height: "70px",
                  overflowX: "hidden",
                  overflowY: "auto",
                  border: "none",
                }}
              ></iframe>

              <div className="agent-info">
                <h2>Contact Information</h2>
                <div>
                  <label htmlFor="email" className="loans-form-label">
                    {" "}
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="loans-form-input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="mt-20">
                  <label htmlFor="phone" className="loans-form-label">
                    {" "}
                    Phone number
                  </label>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    className="loans-form-input"
                    value={phoneNumber}
                    onChange={(e) => {
                      let num = replaceSpecialChar(e.target.value);
                      const phone = formatPhone(num);
                      setPhoneNumber(phone);
                    }}
                  />
                </div>
              </div>

              <div className="agent-info">
                <h2>Languages</h2>
                <div>
                  <label
                    htmlFor="languages"
                    className="loans-form-label hidden"
                  >
                    {" "}
                    Languages Spoken
                  </label>
                  <input
                    type="languages"
                    name="languages"
                    id="languages"
                    className="loans-form-input"
                    value={languages}
                    onChange={(e) => setLanguages(e.target.value)}
                  />
                </div>
              </div>

              <div className="agent-info">
                <h2>State License Number</h2>
                <div className="loans-mb-3">
                  <label htmlFor="license" className="loans-form-label hidden">
                    State License Numbers
                  </label>
                  <input
                    type="text"
                    name="license"
                    id="license"
                    className="loans-form-input"
                    value={license}
                    onChange={(e) => setLicense(e.target.value)}
                  />
                </div>
              </div>

              <div className="agent-info">
                <h2>Brokerage</h2>
                <div className="loans-mb-3">
                  <label
                    htmlFor="brokerage"
                    className="loans-form-label hidden"
                  >
                    Brokerage
                  </label>
                  <input
                    type="text"
                    name="brokerage"
                    id="brokerage"
                    className="loans-form-input"
                    value={brokerage}
                    onChange={(e) => setBrokerage(e.target.value)}
                  />
                </div>
                <div className="loans-mb-3">
                  <label
                    htmlFor="brokerage-number"
                    className="loans-form-label"
                  >
                    Brokerage Phone Number
                  </label>
                  <input
                    type="text"
                    name="brokerage-number"
                    id="brokerage-number"
                    className="loans-form-input"
                    value={brokerageNumber}
                    onChange={(e) => {
                      let num = replaceSpecialChar(e.target.value);
                      const phone = formatPhone(num);
                      setBrokerageNumber(phone);
                    }}
                  />
                </div>
              </div>

              <div className="agent-info">
                <h2>Areas Served</h2>

                {areas.map((area) => (
                  <div key={area} id={area} className="area-btn">
                    {area}
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        console.log(area);
                        // Remove from State
                        const updatedArr = removeFromArr(areas, area);
                        setAreas(updatedArr);
                        // Hide
                        document.getElementById(`${area}`).style.display =
                          "none";
                      }}
                    >
                      X
                    </span>
                  </div>
                ))}

                <div className="loans-input-flex">
                  <div>
                    <label htmlFor="city" className="loans-form-label">
                      City, State
                    </label>
                    <PlacesAutocomplete
                      setFormattedAddress={setFormattedAddress}
                      formattedAddress={formattedAddress}
                    />
                  </div>
                  <div>
                    <button
                      className="areas-btn"
                      onClick={(e) => {
                        e.preventDefault();

                        console.log(formattedAddress);

                        // Check for Content
                        const cityTrimmed = formattedAddress.trim();

                        if (cityTrimmed.length !== 0) {
                          // Check for Duplicates
                          const hasDuplicates = arrayContains(
                            areas,
                            formattedAddress
                          );
                          if (hasDuplicates === false) {
                            setAreas([...areas, formattedAddress]);
                          }
                        }
                      }}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="agent-col-2 edit-profile-page">
              <div className="loans-mb-3">
                <label htmlFor="description" className="loans-form-label">
                  Profile Description
                </label>
                <textarea
                  type="text"
                  name="description"
                  id="description"
                  className="loans-form-input agent-signup-textarea"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <p
                  className={
                    canSubmit === false
                      ? "character-count"
                      : "character-count error"
                  }
                >
                  {description.length} / 1500{" "}
                </p>
              </div>

              <button
                className="agent-btn update-agent"
                onClick={handleFormSubmit}
              >
                Update Information
              </button>

              {showError ? (
                <div className="message-container">
                  <p className="error-message">
                    Something has gone wrong with your request, please try again
                    later.
                  </p>
                </div>
              ) : (
                <></>
              )}
              {emailAlert === true ? (
                <div className="message-container">
                  <p className="error-message">Email address already in use.</p>
                </div>
              ) : (
                <></>
              )}
            </div>
          </main>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;

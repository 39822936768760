import React from "react";
import { Link } from "react-router-dom";
import headerImg from "../../assets/3-1.png";
import "./header.css";

const Header = () => {
  function toggleMenu() {
    const menu = document.querySelector(".menu");
    const header = document.querySelector(".header");
    const closeIcon = document.querySelector(".closeIcon");
    const menuIcon = document.querySelector(".menuIcon");

    if (menu.classList.contains("showMenu")) {
      menu.classList.remove("showMenu");
      closeIcon.style.display = "none";
      menuIcon.style.display = "block";
      header.classList.remove("front");
    } else {
      header.classList.add("front");
      menu.classList.add("showMenu");
      closeIcon.style.display = "block";
      menuIcon.style.display = "none";
    }
  }

  return (
    <section>
      <div className="header">
        <div>
          <Link to={"/"}>
            <img className="logo" alt="Find A Home Logo" src={headerImg} />
          </Link>
        </div>

        {/* <!-- Main Menu --> */}
        <ul className="menu">
          {/* TODO: Add "Agent Resources" link after recieving Jason's email w/ more info */}
          <li>
            <Link
              to={"https://buscatucasa.com/"}
              target="blank"
              className="menuItem text-shadow decoration-none"
            >
              Busca Tu Casa
            </Link>
          </li>
          <li>
            <Link
              to={"https://findahome.ruuster.com/signup"}
              target="blank"
              className="menuItem text-shadow decoration-none"
            >
              App Download
            </Link>
          </li>
          <li>
            <Link
              to={"/home-loans"}
              className="menuItem text-shadow decoration-none"
            >
              Home Loans
            </Link>
          </li>
          <li>
            <Link
              to={"/search"}
              className="menuItem text-shadow decoration-none"
            >
              Buy
            </Link>
          </li>
          {/* Old "Sell" link: https://findahome.hifello.com/lp/644bdfe72d9dd70019d6bb58 */}
          <li>
            <Link
              to={"https://findahome.hifello.com/lp/654aa58ad4c524002518bb15"}
              target="blank"
              className="menuItem text-shadow decoration-none"
            >
              Sell
            </Link>
          </li>
        </ul>
      </div>
      <button className="hamburger">
        <i
          className="menuIcon material-icons text-shadow"
          onClick={() => toggleMenu()}
        >
          menu
        </i>
        <i
          className="closeIcon material-icons text-shadow"
          onClick={() => toggleMenu()}
        >
          close
        </i>
      </button>
    </section>
  );
};

export default Header;

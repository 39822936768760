import React, { useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import loanImage from "../../assets/loans.png";

const HomeLoans = () => {
  // https://docs.followupboss.com/docs/start-here-brand-new-integration
  // POST https://api.followupboss.com/v1/events
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message1, setMessage1] = useState("");
  const [message2, setMessage2] = useState("");

  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const message = `New Home Loan request from findahomeinusa.com. Looking to find a home in - ${message1} (by/in) ${message2}.`;

    const formData = {
      firstName: firstName,
      lastName: lastName,
      message: message,
      email: email,
      phoneNumber: phoneNumber,
    };

    // console.log(formData);

    fetch("api/sendNewLead", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setShowSuccess(true);
      })
      .catch((err) => {
        console.log(err);
        setShowError(true);
      });
  };

  return (
    <div>
      <Header />
      <img
        className="loans-form-image"
        src={loanImage}
        alt="A family moving into their new home."
      />
      <main>
        <div className="contact-form-container home-loans">
          <div className="loans-main-wrapper">
            <div className="loans-form-wrapper">
              {/* <!-- Form --> */}
              <form onSubmit={handleFormSubmit}>
                <div className="loans-form-title">
                  <h2 className="">Home Loans</h2>
                  <p>
                    Looking to gain a better understanding of your finances?
                    We've got your back! Feel free to reach out to us using the
                    form below, and we'll be in contact by tomorrow.
                  </p>
                </div>
                <hr className="loans-form-hr" />

                <div className="loans-input-flex">
                  <div>
                    <label htmlFor="firstname" className="loans-form-label">
                      First name *
                    </label>
                    <input
                      type="text"
                      name="firstname"
                      id="firstname"
                      className="loans-form-input"
                      required
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div>
                    <label htmlFor="lastname" className="loans-form-label">
                      {" "}
                      Last name *
                    </label>
                    <input
                      type="text"
                      name="lastname"
                      id="lastname"
                      className="loans-form-input"
                      required
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>

                <div className="loans-input-flex">
                  <div>
                    <label htmlFor="email" className="loans-form-label">
                      {" "}
                      Email *
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="loans-form-input"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div>
                    <label htmlFor="phone" className="loans-form-label">
                      {" "}
                      Phone number *
                    </label>
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      className="loans-form-input"
                      required
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                </div>

                <div className="loans-mb-3">
                  <label htmlFor="message1" className="loans-form-label">
                    Where would you like to find a home? *
                  </label>
                  <input
                    type="text"
                    name="message1"
                    id="message1"
                    className="loans-form-input"
                    required
                    value={message1}
                    onChange={(e) => setMessage1(e.target.value)}
                  />
                </div>

                <div className="loans-mb-3">
                  <label htmlFor="message2" className="loans-form-label">
                    How soon do you need to find a home? *
                  </label>
                  <input
                    type="text"
                    name="message2"
                    id="message2"
                    className="loans-form-input"
                    required
                    value={message2}
                    onChange={(e) => setMessage2(e.target.value)}
                  />
                </div>

                {showSuccess ? (
                  <div className="message-container">
                    <p className="success-message">
                      Your message has sent, we'll be in contact with you
                      shortly!
                    </p>
                  </div>
                ) : (
                  <></>
                )}

                {showError ? (
                  <div className="message-container">
                    <p className="error-message">
                      Something has gone wrong with your request, please try
                      again later.
                    </p>
                  </div>
                ) : (
                  <></>
                )}

                <button className="loans-btn" type="submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default HomeLoans;

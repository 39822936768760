import React, { useState } from "react";
import "./style.css";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import PricingGrid from "./grid";
import PricingCards from "./cards";
import Modal from "./modal";

const AgentSignup1 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleFree = () => {
    localStorage.removeItem("plan_type");
    window.location.href = "/agent-signup";
  };
  const handleStandard = () => {
    localStorage.setItem("plan_type", "Standard");
    window.location.href = "/agent-signup";
  };
  const handlePro = () => {
    localStorage.setItem("plan_type", "Pro");
    window.location.href = "/agent-signup";
  };
  const handleEnterprise = () => {
    setModalOpen(true);
  };

  return (
    <div>
      <Header />

      <div className="pricing-header">
        <h1>Find a Home Agent Services</h1>

        <h2>
          Boost Your Real Estate Business with Our Tailored CRM & Networking
          Solutions
        </h2>

        <p>
          Whether you're a solo agent or a growing real estate team, our
          networking tools are designed to streamline your processes, help you
          close deals faster, and build lasting relationships. Choose the
          package that fits your business and unlock features like lead
          generation, and seamless collaboration.
        </p>
      </div>

      <main className="pricing-main">
        {/* Desktop View*/}
        <PricingGrid
          handleFree={handleFree}
          handleStandard={handleStandard}
          handlePro={handlePro}
          handleEnterprise={handleEnterprise}
        />

        {/* Mobile View */}
        <PricingCards
          handleFree={handleFree}
          handleStandard={handleStandard}
          handlePro={handlePro}
          handleEnterprise={handleEnterprise}
        />
      </main>

      <div className="pricing-footer">
        <p>Your Network, Your Deals, Your Success—All in One Platform.</p>
      </div>

      <Footer />

      {modalOpen ? <Modal setModalOpen={setModalOpen} /> : <></>}
    </div>
  );
};

export default AgentSignup1;

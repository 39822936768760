import React, { useEffect, useState } from "react";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./signupSuccess.css";
import { postNotice, updateAgent } from "../../utils/api";

const SignupSuccess = () => {
  const plan_type = localStorage.getItem("plan_type");
  const agent = JSON.parse(localStorage.getItem("agent"));

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    console.log(plan_type, agent);

    if (plan_type) {
      // Update the Agent's Plan Type
      updateAgent({ plan_type: plan_type }, agent.id)
        .then((res) => res.json())
        .then((data) => {
          const noticeData = {
            content: `${agent.name} subscribed to the ${plan_type} plan.`,
            actions: "",
            website: agent.website ? agent.website : "Findahome",
            user_id: null,
            agent_id: null,
          };

          // Notify the Admin
          postNotice(noticeData)
            .then((res) => res.json())
            .then((data) => {
              localStorage.removeItem("plan_type");
              localStorage.removeItem("agent");
              setLoading(false);
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    } else {
      setLoading(false);
    }
  }, []);

  if (isLoading) {
    return (
      <>
        <Header />
        <div className="signup-success-page">
          <h1>Processing...</h1>

          <p>Please do not refresh the page.</p>
        </div>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header />

      <div className="signup-success-page">
        <h1>Success!</h1>

        <p>
          Thanks for signing up! We’re currently reviewing your profile
          information for approval. To access and manage your account, visit the{" "}
          <a href="/user-login" className="link">
            User Login
          </a>{" "}
          page.
        </p>

        <a href="/">
          <button>Back to Home</button>
        </a>
      </div>

      <Footer />
    </>
  );
};

export default SignupSuccess;

import React, { useState } from "react";
import headerImg from "../../../../assets/3-1.png";
import Auth from "../../../../utils/auth";
import { useNavigate } from "react-router-dom";

const AgentPortal = ({ user }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header>
      <div onClick={() => navigate("/agent-portal")}>
        <img
          className="agent-portal-logo"
          alt="Find A Home Logo"
          src={headerImg}
        />
      </div>

      <div
        className={isOpen === false ? "" : "nav-open"}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <p>{user.name}</p>
        <div
          className={
            isOpen === false ? "agent-portal-menu" : "agent-portal-menu open"
          }
        >
          <ul>
            <li onClick={() => Auth.logout()}>Logout</li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default AgentPortal;

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

// Pages/Components
import Admin from "./pages/Admin";
import Contact from "./pages/Contact/index";
import ForgotPassword from "./pages/ForgotPassword";
import Home from "./pages/Home/index";
import HomeLoans from "./pages/HomeLoans";
import Login from "./pages/Login";
import OTPValidation from "./pages/OTP";
import Search from "./pages/Search/index";
import SignupSuccess from "./pages/SignupSuccess";
import ImageUpload from "./components/ImageUpload";

// Find An Agent Feature
import AgentPortal from "./pages/FindAAgent/Portal/Dashboard/index";
import AgentPortalEdit from "./pages/FindAAgent/Portal/Edit/index";
import AgentProfile from "./pages/FindAAgent/Profile";
import AgentSearch from "./pages/FindAAgent/Search";
import AgentSignup from "./pages/FindAAgent/Signup";
import PricingPage from "./pages/FindAAgent/Pricing";

// Find a Lender Feature
import LenderPortal from "./pages/FindALender/Portal/Dashboard/index";
import LenderPortalEdit from "./pages/FindALender/Portal/Edit/index";
import LenderProfile from "./pages/FindALender/Profile";
import LenderSearch from "./pages/FindALender/Search";
import LenderSignup from "./pages/FindALender/Signup";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/home-loans" element={<HomeLoans />} />
        <Route path="/search" element={<Search />} />
        <Route path="/pricing-plans" element={<PricingPage />} />
        <Route path="/fahiu2024" element={<ImageUpload />} />

        {/* Authentication */}
        <Route path="/user-login" element={<Login />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/otp" element={<OTPValidation />} />

        {/* Agent In the Area Routes */}
        <Route path="/find-an-agent" element={<AgentSearch />} />
        <Route path="/agent-profile/:id" element={<AgentProfile />} />
        <Route path="/agent-signup" element={<AgentSignup />} />
        <Route path="/signup-success" element={<SignupSuccess />} />
        <Route path="/agent-portal" element={<AgentPortal />} />
        <Route path="/agent-portal-edit/:id" element={<AgentPortalEdit />} />

        {/* Find a Lender Routes */}
        <Route path="/find-a-lender" element={<LenderSearch />} />
        <Route path="/lender-profile/:id" element={<LenderProfile />} />
        <Route path="/lender-signup" element={<LenderSignup />} />
        <Route path="/signup-success" element={<SignupSuccess />} />
        <Route path="/lender-portal" element={<LenderPortal />} />
        <Route path="/lender-portal-edit/:id" element={<LenderPortalEdit />} />

        <Route path="*" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
